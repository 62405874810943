import React from "react"
import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Splash from "../components/sections/Splash"
import ConflictCards from "../components/sections/ConflictCards"
import PickupParallax from "../components/sections/PickupParallax"
import CoverImage from "../components/sections/CoverImage"
import TextImage from "../components/sections/TextImage"
import VideoText from "../components/sections/VideoText"
import VideoGrid from "../components/sections/VideoGrid"
import DataGrid from "../components/sections/DataGrid"

const MaliPage = ({ data }) => {
  const intl = useIntl()
  const { locale } = intl
  const { markdownRemark, allFile } = data
  const {
    id,
    title,
    startYear,
    endYear,
    firstVideoTitle,
    firstVideoText,
    secondVideoTitle,
    secondVideoText,
    thirdVideoTitle,
    thirdVideoText,
    fourthVideoTitle,
    fourthVideoText,
    sectionTitle,
    sectionText,
    homeSectionTitle,
    homeSectionText,
  } = markdownRemark.frontmatter

  // Images
  const imageOne = allFile.nodes.find(image => image.name === `${id}_image_1`)
  const imageTwo = allFile.nodes.find(image => image.name === `${id}_image_2`)
  const imageThree = allFile.nodes.find(image => image.name === `${id}_image_3`)

  // Video images
  const firstVideoImage = allFile.nodes.find(
    image => image.name === `${id}_1_${locale}`
  )
  const secondVideoImage = allFile.nodes.find(
    image => image.name === `${id}_2_${locale}`
  )
  const thirdVideoImage = allFile.nodes.find(
    image => image.name === `${id}_3_${locale}`
  )
  const fourthVideoImage = allFile.nodes.find(
    image => image.name === `${id}_4_${locale}`
  )

  // Video paths
  const videoSrc = {
    first: {
      en: '533049156',
      sv: '533053465'
    },
    second: {
      en: '533049172',
      sv: '533053475'
    },
    third: {
      en: '533049206',
      sv: '533053540'
    },
    fourth: {
      en: '533049305',
      sv: '533053674'
    },
  }

  // Videos
  const videos = [
    {
      id: 1,
      video: videoSrc.second[locale],
      image: secondVideoImage,
      title: secondVideoTitle,
      text: secondVideoText,
    },
    {
      id: 2,
      video: videoSrc.third[locale],
      image: thirdVideoImage,
      title: thirdVideoTitle,
      text: thirdVideoText,
    },
    {
      id: 3,
      video: videoSrc.fourth[locale],
      image: fourthVideoImage,
      title: fourthVideoTitle,
      text: fourthVideoText,
    },
  ]

  // Conflict stats
  const conflictStats = [
    {
      id: 1,
      value: "15306",
      text: intl.formatMessage({ id: "conflict.un_participants" }),
    },
    {
      id: 2,
      value: "6784",
      text: intl.formatMessage({ id: "conflict.data_deaths" }),
    },
    {
      id: 3,
      value: "374000",
      text: intl.formatMessage({ id: "conflict.data_on_run" }),
    },
  ]

  return (
    <Layout>
      <SEO title={title} />
      <Splash
        title={title}
        year={`${startYear} - ${endYear}`}
        image={imageOne}
        color="white"
      />
      <DataGrid
        data={conflictStats}
        backgroundColor="#BDAB9C"
        textColor="white"
      />
      <VideoText
        title={firstVideoTitle}
        description={firstVideoText}
        image={firstVideoImage}
        video={videoSrc.first[locale]}
        backgroundColor="#797E64"
        textColor="white"
      />
      <PickupParallax title={sectionTitle} description={sectionText} />
      <CoverImage image={imageTwo} />
      <VideoGrid videos={videos} backgroundColor="#504D4B" textColor="white" />
      <TextImage
        title={homeSectionTitle}
        description={homeSectionText}
        backgroundColor="white"
        large
      />
      <CoverImage image={imageThree} />
      <ConflictCards
        title={intl.formatMessage({ id: "conflict.discover_title" })}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query MaliQuery($locale: String) {
    markdownRemark(frontmatter: { lang: { eq: $locale }, id: { eq: "mali" } }) {
      html
      frontmatter {
        id
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        startYear
        endYear
        firstVideoTitle
        firstVideoText
        secondVideoTitle
        secondVideoText
        thirdVideoTitle
        thirdVideoText
        fourthVideoTitle
        fourthVideoText
        sectionTitle
        sectionText
        homeSectionTitle
        homeSectionText
      }
    }
    allFile(filter: { relativeDirectory: { eq: "conflicts" } }) {
      nodes {
        id
        name
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

export default MaliPage

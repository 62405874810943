import styled from "styled-components"
import { motion } from "framer-motion"
import { breakpoints } from "../../../utils/styles"

export const Section = styled.div`
  background: ${({ theme }) => theme.color.backgroundSectionOne};
  overflow: hidden;
`

export const Content = styled(motion.div)`
  color: ${({ theme }) => theme.color.black};
  max-width: 900px;
  min-height: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 64px 32px;

  @media (max-width: ${breakpoints.l}px) {
    min-height: 400px;
    padding: 64px 24px;
  }

  @media (max-width: ${breakpoints.s}px) {
    text-align: left;
    align-items: flex-start;
  }
`

export const Ground = styled.div`
  background: #dac9b7;
  height: 10vw;
`

export const ImageContainer = styled.div`
  position: relative;
  line-height: 0.8rem;
`

export const Image = styled(motion.img)`
  width: 60%;
  margin-bottom: 0;
  @media (max-width: ${breakpoints.l}px) {
  }
`

export const FrontTire = styled(motion.img)`
  position: absolute;
  bottom: 12%;
  left: 6.6%;
  width: 2.6%;
  z-index: 1;
  margin: 0;
`

export const BackTire = styled(motion.img)`
  position: absolute;
  bottom: 12%;
  left: 37.9%;
  width: 2.6%;
  z-index: 1;
  margin: 0;
`

export const Missile = styled(motion.img)`
  position: absolute;
  top: -3%;
  right: 34%;
  width: 6%;
`

export const Explosion = styled(motion.img)`
  position: absolute;
  bottom: 2%;
  right: 2%;
  width: 10%;
`

export const Description = styled.p`
  font-size: 24px;
  line-height: 32px;

  @media (max-width: ${breakpoints.l}px) {
    font-size: 16px;
    line-height: 24px;
  }
`

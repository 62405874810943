import React, { useLayoutEffect } from "react"
import PropTypes from "prop-types"
import ReactHtmlParser from "react-html-parser"
import { useInView } from "react-intersection-observer"
import { useAnimation } from "framer-motion"

import Heading, { Type } from "../../UI/Heading"

import {
  Section,
  Content,
  Image,
  Description,
  Ground,
  ImageContainer,
  BackTire,
  FrontTire,
  Missile,
  Explosion,
} from "./styles"

import pickupImage from "../../../assets/conflicts/pickup/pickup.png"
import wheelImage from "../../../assets/conflicts/pickup/wheel.png"
import missileImage from "../../../assets/conflicts/pickup/missile.png"
import explosionImage from "../../../assets/conflicts/pickup/explosion.png"

const contentVariants = {
  visible: { scale: 1, opacity: 1 },
  hidden: { scale: 1.2, opacity: 0 },
}

const pickupVariants = {
  visible: { x: "1vw" },
  hidden: { x: "100vw" },
}

const wheelVariants = {
  visible: { rotate: 0, x: 0 },
  hidden: { rotate: 720, x: "100vw" },
}

const missileVariants = {
  visible: {
    x: "40vw",
    y: "-16vw",
    display: "block",
    transitionEnd: { display: "none" },
  },
  hidden: { x: 0, y: 0, display: "none" },
}

const explosionVariants = {
  visible: {
    scale: 6,
    opacity: 1,
    rotate: 180,
    transitionEnd: { display: "none" },
  },
  hidden: { scale: 1, opacity: 0, rotate: 0 },
}

const MineralParallax = ({ title, description }) => {
  const [contentRef, inContentView] = useInView()
  const [pickupRef, inPickupView] = useInView()
  const contentControls = useAnimation()
  const pickupControls = useAnimation()

  useLayoutEffect(() => {
    if (inContentView) {
      contentControls.start("visible")
    }
  }, [contentControls, inContentView])

  useLayoutEffect(() => {
    if (inPickupView) {
      pickupControls.start("visible")
    }
  }, [pickupControls, inPickupView])

  return (
    <Section>
      <Content
        ref={contentRef}
        animate={contentControls}
        initial="hidden"
        transition={{ duration: 0.8 }}
        variants={contentVariants}
      >
        <Heading type={Type.h2}>{title}</Heading>
        <Description>{ReactHtmlParser(description)}</Description>
      </Content>
      <ImageContainer>
        <Missile
          initial="hidden"
          transition={{ delay: 2, duration: 0.4 }}
          animate={pickupControls}
          variants={missileVariants}
          src={missileImage}
          alt="missile"
        />
        <Explosion
          initial="hidden"
          transition={{ delay: 2.3, duration: 0.6 }}
          animate={pickupControls}
          variants={explosionVariants}
          src={explosionImage}
          alt="Explosion"
        />
        <FrontTire
          initial="hidden"
          transition={{ duration: 2 }}
          animate={pickupControls}
          variants={wheelVariants}
          src={wheelImage}
          alt="front tire"
        />
        <BackTire
          initial="hidden"
          transition={{ duration: 2 }}
          animate={pickupControls}
          variants={wheelVariants}
          src={wheelImage}
          alt="back tire"
        />
        <Image
          alt="pickup"
          initial="hidden"
          ref={pickupRef}
          src={pickupImage}
          animate={pickupControls}
          variants={pickupVariants}
          transition={{ duration: 2 }}
        />
      </ImageContainer>
      <Ground />
    </Section>
  )
}

MineralParallax.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default MineralParallax
